<template>
  <div class="com14 w-screen relative" ref="scrollBox">
    <!-- ---------------------------------Sticky area -START------------------------------------- -->
    <div
      class="sticky w-full flex flex-col justify-evenly items-center lg:justify-center top-0"
    >
      <div
        class="w-full h-screen absolute px-2 py-14 lg:px-20 lg:py-20 2xl:pt-32 flex flex-col justify-between z-10"
      >
        <!-- title -->
        <div class="flex justify-between items-center">
          <div
            class="w-3/5 lg:w-auto flex flex-col items-start lg:items-center h-24 px-3 lg:p-5 lg:border border-gray-400 lg:bg-white"
          >
            <span
              class="block text-xl w-9/12 lg:w-auto text-left lg:text-center lg:text-3xl font-bold"
              >我的預期月租金適合住哪裡</span
            >
            <span class="block text-xs lg:text-lg"
              >一 從同價位物件密度看雙北各區</span
            >
          </div>
          <div class="relative w-2/5 h-24 md:w-1/5">
            <transition name="fadelegend">
              <img
                class="absolute w-full h-auto"
                :src="mapLegend"
                :key="bgKey"
                alt=""
              />
            </transition>
          </div>
        </div>
      </div>
      <!-- MapBg -->
      <div class="relative w-full h-screen flex justify-center items-center">
        <transition name="fadebg">
          <div
            class="absolute w-full h-screen bg-cover bg-center"
            :key="bgKey"
            :class="bgArr[bgId]"
          ></div>
        </transition>
        <MapCircles
          v-for="(circle, index) in circleList"
          :key="index"
          :circleData="circle"
          :show="bgId == circle.show"
        />
      </div>
      <transition name="fadebg">
        <img
          class="hidden lg:block w-10% absolute bottom-6 left-8"
          :key="bgId"
          :src="'/images/desktop14/box/' + bgBox[bgId]"
          alt=""
        />
      </transition>
      <Numbers :bgId="bgId" />
    </div>
    <!-- ---------------------------------Sticky area -END------------------------------------- -->
    <div
      class="absolute top-0 w-full h-screen px-2 py-14 lg:px-20 lg:py-10 2xl:pt-32 flex flex-col justify-end z-10"
    >
      <div
        class="flex justify-center lg:justify-end items-center relative bg-white bg-opacity-50 lg:bg-none lg:bg-opacity-0"
      >
        <div
          class="w-full lg:w-1/4 text-justify p-3 lg:border lg:border-gray-400 lg:bg-white lg:bg-opacity-80"
        >
          {{ firstContext }}
        </div>
      </div>
    </div>
    <div
      v-for="box in boxArray"
      :key="box.ref"
      class="boxes z-20 scroll-full"
      ref="box"
    >
      <div
        class="w-full h-screen absolute px-2 lg:px-20 lg:py-10 2xl:pt-32 flex flex-col justify-end"
      >
        <div
          class="flex justify-center lg:justify-end items-center relative bg-white bg-opacity-50 lg:bg-none lg:bg-opacity-0"
        >
          <div
            v-if="box.context"
            class="w-full lg:w-1/4 text-justify text-black p-3 lg:border lg:border-gray-400 lg:bg-white lg:bg-opacity-80"
          >
            {{ box.context }}
          </div>
        </div>
      </div>
    </div>
    <div class="boxes"></div>
  </div>
</template>

<script>
import MapCircles from "./components/MapCircles.vue";
import Numbers from "./components/Numbers.vue";
import { circles, circlesM } from "./circleData";

export default {
  name: "Desktop14",
  components: { MapCircles, Numbers },
  data: function () {
    return {
      bgArr: [
        ["lg:bg-map14_0_6", "bg-map14_0_6M"],
        ["lg:bg-map14_0_6", "bg-map14_0_6M"],
        ["lg:bg-map14_6_7", "bg-map14_6_7M"],
        ["lg:bg-map14_7_8", "bg-map14_7_8M"],
        ["lg:bg-map14_8_9", "bg-map14_8_9M"],
        ["lg:bg-map14_9_10", "bg-map14_9_10M"],
        ["lg:bg-map14_10_11", "bg-map14_10_11M"],
        ["lg:bg-map14_11_12", "bg-map14_11_12M"],
        ["lg:bg-map14_12_13", "bg-map14_12_13M"],
        ["lg:bg-map14_13_14", "bg-map14_13_14M"],
        ["lg:bg-map14_14_15", "bg-map14_14_15M"],
      ],
      bgBox: [
        "0-6kBox.jpg",
        "0-6kBox.jpg",
        "6-7kBox.jpg",
        "7-8kBox.jpg",
        "8-9kBox.jpg",
        "9-10kBox.jpg",
        "10-11kBox.jpg",
        "11-12kBox.jpg",
        "12-13kBox.jpg",
        "13-14kBox.jpg",
        "14-15kBox.jpg",
      ],
      bgId: 0,
      bgKey: 0,
      firstContext:
        "如果可負擔這概念還不夠直覺，不妨我們用預期的租金來看看自己能租到哪裡的房子吧！這次我們不再以區里界當單位，直接看每個房源點位在不同價位時會聚集在哪些區域。",
      boxArray: [
        {
          id: "box2",
          context:
            "在6,000元以下的租金區間，房源幾乎分布在新北市，而且集中在三重區、新莊區以及中和區一帶。",
        },
        { id: "box3", context: "" },
        { id: "box4", context: "" },
        { id: "box5", context: "" },
        {
          id: "box6",
          context:
            "隨著價格逐漸上升，在9,000-10,000元區間，新北市與台北市房源密度開始靠近，台北萬華區與中山區部分尤其明顯。",
        },
        { id: "box7", context: "" },
        { id: "box8", context: "" },
        { id: "box9", context: "" },
        { id: "box10", context: "" },
        {
          id: "box11",
          context:
            "當平均每房月租金來到14,000-15,000元時，新北市房源僅剩少數地區密度較高，其他大多位於台北市中山區、萬華區，以及公館、信義一帶。這種租金上的地理區位落差，形成了無形的篩選與推力，讓眾多北漂青年只能望屋興嘆。",
        },
      ],
      circleList: [],
    };
  },
  computed: {
    mapLegend: function () {
      if (this.bgId == 1) return "/images/desktop14/legend/legend6k.png";
      else if (this.bgId == 5) return "/images/desktop14/legend/legend9k.png";
      else if (this.bgId == 10) return "/images/desktop14/legend/legend14k.png";
    },
  },
  methods: {
    setBgId(id) {
      if (this.bgId != id) {
        this.bgKey++;
        this.bgId = id;
      }
    },
    scroll() {
      //距離網頁最頂端之滾動距離
      let top = window.scrollY;
      //deskTop14組件離父層最頂部距離
      let scrollBox = this.$refs.scrollBox.offsetTop;
      //box高度
      let boxHeight = this.$refs.box[0].clientHeight;
      //要切換到box2元素內容之滾動距離(與網頁最頂端)
      let box2h = scrollBox + this.$refs.box[0].offsetTop;
      //要切換到box3元素內容之滾動距離(與網頁最頂端)
      let box3h = scrollBox + this.$refs.box[1].offsetTop;
      //要切換到box3元素內容之滾動距離(與網頁最頂端)
      let box4h = scrollBox + this.$refs.box[2].offsetTop;
      let box5h = scrollBox + this.$refs.box[3].offsetTop;
      let box6h = scrollBox + this.$refs.box[4].offsetTop;
      let box7h = scrollBox + this.$refs.box[5].offsetTop;
      let box8h = scrollBox + this.$refs.box[6].offsetTop;
      let box9h = scrollBox + this.$refs.box[7].offsetTop;
      let box10h = scrollBox + this.$refs.box[8].offsetTop;
      let box11h = scrollBox + this.$refs.box[9].offsetTop;

      if (top < box2h) {
        this.setBgId(0);
      } else if (top > box2h && top < box3h) {
        //0-6k
        this.setBgId(1);
        this.setScroll(top, box2h, boxHeight);
      } else if (top > box3h && top < box4h) {
        this.setBgId(2);
      } else if (top > box4h && top < box5h) {
        this.setBgId(3);
      } else if (top > box5h && top < box6h) {
        this.setBgId(4);
      } else if (top > box6h && top < box7h) {
        //9k-10k
        this.setBgId(5);
        this.setScroll(top, box6h, boxHeight);
      } else if (top > box7h && top < box8h) {
        this.setBgId(6);
      } else if (top > box8h && top < box9h) {
        this.setBgId(7);
      } else if (top > box9h && top < box10h) {
        this.setBgId(8);
      } else if (top > box10h && top < box11h) {
        this.setBgId(9);
      } else if (top > box11h) {
        //14k-15k
        this.setBgId(10);
        this.setScroll(top, box11h, boxHeight);
      }
    },
    resize() {
      let innerWidth = window.innerWidth;
      if (innerWidth <= 1024) {
        this.circleList = circlesM;
      } else this.circleList = circles;
    },
    setScroll(scroll, offset, height) {
      document.body.style.setProperty("--scroll", (scroll - offset) / height);
    },
  },
  mounted() {
    this.scroll();
    window.addEventListener("scroll", this.scroll, true);
    this.resize();
    window.addEventListener("resize", this.resize, true);
  },
};
</script>

<style>
.mapimg {
  opacity: 0;
  transition: all 0.8s ease-in-out;
}
.trans-item {
  transition: all 0.5s ease-in-out;
}
.boxes {
  width: 100%;
  height: 100vh;
  position: relative;
}

.scroll-full {
  animation: scrollFull 1s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -1s);
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.fadebg-enter {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.fadebg-leave-to {
  opacity: 1;
  transition: all 0.5s ease-in;
}
.fadebg-enter-to {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.fadelegend-enter,
.fadelegend-leave-to {
  opacity: 0;
  transition: all 0.5s ease;
}
.fadelegend-enter-to,
.fadelegend-leave {
  opacity: 1;
  transition: all 0.5s ease;
}

@keyframes scrollFull {
  0% {
    opacity: 0.3;
  }
  20%{
    opacity: 1;
  }
  50%{
    opacity: 1;
  }
  100%{
      opacity: 0.3;
  }

}
</style>
