export const mobileConfig = {
  chart: {
    type: "bar",
    width: "100%",
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 0,
      dataLabels: {
        position: "top",
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    labels: {
      formatter: (val) => {
        return val / 1000 + "K";
      },
      rotate: -90,
      offsetX: -10,
    },
  },
  xaxis: {
    position: 'bottom',
    labels: {
      rotate: 0,
      hideOverlappingLabels: false,
    }
  },
  legend: {
    show: false,
  },
  grid: {
    show: false,
  },
  tooltip: {
    y: {
      formatter: function (value) {
        return value.toLocaleString();
      },
    },
  },
  colors: ["#F85A31", "#FFD23F", "#c9c9c9"],
};

export const desktopConfig = {
  chart: {
    type: "bar",
    width: "100%",
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      dataLabels: {
        position: "top",
      },
    },
  },
  dataLabels: {
    formatter: function (val) {
      return val.toLocaleString("zh-TW");
    },
    offsetY: -20,
    style: {
      fontSize: "12px",
      colors: ["#333333"],
    },
  },
  yaxis: {
    labels: {
      formatter: (val) => {
        return val / 1000 + "K";
      },
    },
  },
  legend: {
    show: false,
  },
  grid: {
    show: false,
  },
  tooltip: {
    y: {
      formatter: function (value) {
        return value.toLocaleString();
      },
    },
  },
  colors: ["#F85A31", "#FFD23F", "#c9c9c9"],
};
