import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/main.scss";
import "./assets/tailwind.css";
import VueApexCharts from "vue-apexcharts";
import "img-comparison-slider";

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
Vue.config.ignoredElements = [/img-comparison-slider/];

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
