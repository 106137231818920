<template>
  <div class="relative w-screen" ref="scrollBox_drag">
    <div class="w-full h-screen sticky top-0 z-max">
      <!-- <Drag-teach v-if="dragShow === 'teach'" />
      <Drag-view-one v-if="dragShow === 'viewOne'" />
      <Drag-view-two v-if="dragShow === 'viewTwo'" />
      <Drag-view-three v-if="dragShow === 'viewThree'" /> -->
      <component :is="dragShow" />
    </div>

    <div class="boxes" ref="boxHeight"></div>
    <div class="boxes"></div>
    <div class="boxes"></div>
    <div class="boxes"></div>
  </div>
</template>
<script>
import DragTeach from "@/components/dragTeach.vue";
import DragViewOne from "@/components/dragView-one.vue";
import DragViewTwo from "@/components/dragView-two.vue";
import DragViewThree from "@/components/dragView-three.vue";
export default {
  name: "dragTest",
  components: { DragTeach, DragViewOne, DragViewTwo, DragViewThree },
  data() {
    return {
      dragShow: "DragTeach",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandler);
  },
  methods: {
    scrollHandler() {
      const _this = this;
      //距離網頁最頂端之滾動距離
      let top = window.scrollY;
      //barChart組件離父層最頂部距離
      let scrollBox = this.$refs.scrollBox_drag.offsetTop;
      //滾動多少高度後切換內容
      let h = this.$refs.boxHeight.offsetTop;

      if (top > scrollBox + 3 * h) {
        _this.dragShow = "DragViewThree";
      } else if (top > scrollBox + 2 * h) {
        _this.dragShow = "DragViewTwo";
      } else if (top > scrollBox + h) {
        _this.dragShow = "DragViewOne";
      } else {
        _this.dragShow = "DragTeach";
      }

      // if (top > scrollBox ) {
      //   _this.dragShow = "DragTeach";
      // } else if (top > scrollBox + h) {
      //   _this.dragShow = "viewOne";
      // } else if (top > scrollBox + 2 * h) {
      //   _this.dragShow = "viewTwo";
      // } else if (top > scrollBox + 3 * h) {
      //   _this.dragShow = "viewThree";
      // }
    },
  },
};
</script>
