<template>
  <div
    id="overview"
    class="com2 w-screen flex flex-col justify-center min-h-screen xl:h-screen xl:flex-row xl:justify-between xl:items-center 2xl:py-52 bg-bgGray"
  >
    <div
      class="text-customGreen text-3xl text-center font-bold my-10 xl:hidden"
    >
      Overview
    </div>
    <div
      class="relative overflow-x-hidden xl:pb-10 xl:ml-5% 2xl:ml-13% 2xl:pb-0"
    >
      <!-- 社會新鮮人 /低年資上班族-->
      <div
        v-for="(data, index) in dataList"
        :key="index"
        class="flex items-center justify-around my-5 px-2 2xl:mb-20"
      >
        <!-- ----頭像&年齡範圍 -->
        <div class="flex flex-col items-center w-84px mr-2 md:w-auto md:mr-7">
          <img
            class="w-70px mb-2 md:mb-5 md:w-24 xl:w-32"
            :src="data.img"
            alt="graduate"
          />
          <div class="text-sm md:text-md font-bold">{{ data.title }}</div>
          <div class="text-sm md:text-md">{{ data.subtitle }}</div>
        </div>
        <!-- ----薪資中位數 -->
        <div class="flex flex-col items-center">
          <div class="flex border-b md:border-b-2 border-black items-end">
            <span
              class="text-customGreen text-2xl md:text-3xl xl:text-5xl font-bold italic"
              >{{ data.salary }}</span
            >
            <span class="text-xs ml-2 font-bold">元</span>
          </div>
          <span class="text-xs md:text-md xl:text-xl font-bold mt-1"
            >薪資中位數</span
          >
          <span
            class="text-xs md:text-sm xl:text-lg font-bold my-1 xl:my-2 text0-7"
            ><span class="text-xs md:text-sm xl:text-2xl text-customGreen">{{
              data.age
            }}</span
            >之薪資中位數</span
          >
          <div class="flex border-b md:border-b-2 border-black items-end">
            <span
              class="text-customGreen text-2xl md:text-3xl xl:text-5xl font-bold italic"
              >{{ data.rentPrice }}</span
            >
            <span class="text-xs ml-2 font-bold">元</span>
          </div>
          <span class="text-xs md:text-md xl:text-xl font-bold mt-1"
            >合理房租上限</span
          >
          <span
            class="text-xs md:text-sm xl:text-lg font-bold my-1 xl:my-2 text0-7"
            >薪資中位數 x 33%</span
          >
        </div>
        <!-- ---可負擔房源比 -->
        <div
          class="relative flex flex-col items-center ml-2 md:ml-14 xl:items-end xl:ml-36"
        >
          <div class="border-b-2 md:border-b-4 border-black items-end">
            <span
              class="text-customGreen text-4xl md:text-5xl xl:text-7xl font-bold italic"
              >{{ data.rate }}</span
            >
          </div>
          <span class="font-bold text-base md:text-xl xl:text-2xl my-2"
            >可負擔房源比</span
          >
          <span
            class="absolute w-52 text-xs -bottom-2 -ml-2 md:w-80 md:text-sm xl:-ml-0 xl:-bottom-6 xl:text-right xl:text-lg xl:scale-100 font-bold transform scale-65"
            >{{ data.text }}</span
          >
        </div>
      </div>
      <div class="hidden absolute bottom-0 right-0 text-right xl:block">
        <div class="text-lg font-bold text-customGreen">
          可負擔定義：月租金$11,000以下之物件
        </div>
        <div class="text-sm text-customGreen">
          (包含管理費，整層以平均每房月租金計算)
        </div>
        <div
          class="text-xs text-lightGray opacity-60 transform origin-right scale-95"
        >
          (Icon : from parkjisun in Noun Project)
        </div>
      </div>
    </div>
    <!-- ----------overview----------------------------------------------------------- -->
    <div
      class="flex flex-col w-full xl:w-1/4 xl:h-full xl:justify-center xl:mr-8% 2xl:justify-start"
    >
      <!-- title -->
      <span
        class="text-customGreen hidden xl:block text-3xl xl:text-4xl text-left mb-20 font-bold xl:order-1"
        >Overview</span
      >
      <!-- subtitle -->
      <div
        class="flex flex-col items-end w-11/12 mx-auto text-customGreen xl:w-full xl:order-3 xl:items-start xl:mt-3"
      >
        <span class="text-xs font-bold xl:text-sm">資料來源</span>
        <div
          class="flex justify-end my-1 text-xs transform origin-right scale-85 xl:justify-start w-full xl:text-sm xl:text-left xl:transform-none whitespace-nowrap flex-nowrap"
        >
          <a
            href="https://rentalhouse.g0v.ddio.io/download/"
            target="_blank"
            class="underline"
          >
            591租屋網2020年爬蟲數據</a
          >
          、
          <a
            href="https://earnings.dgbas.gov.tw/template.html?selectid=1"
            target="_blank"
            class="underline"
            >行政院主計總處薪情平台－108年總薪資分布</a
          >
        </div>
        <span
          class="mb-8 text-xs text-lightGray opacity-60 transform origin-right scale-85 xl:hidden"
          >(ICON : from parkjisun in Noun Project)</span
        >
      </div>
      <!-- content -->
      <div
        class="w-11/12 mx-auto xl:text-lg xl:w-auto xl:bg-white xl:border xl:border-customGray xl:order-2 xl:p-3"
      >
        <span class="block text-justify xl:hidden">
          台北的租金高不可攀，在薪資成長緩慢房租卻持續攀升的狀況下，青年在雙北地區的居住壓力持續升高，共居合租因此成為許多人的居住解方。
          而接下來討論的可負擔，則以二個族群的合理房租上限取平均一一<span
            class="font-bold"
            >11,000元</span
          >作為基準進行探討。
        </span>
        <span class="hidden xl:block xl:text-justify xl:mb-1"
          >台北的租金高不可攀，在薪資成長緩慢房租卻持續攀升的狀況下，青年在雙北地區的居住壓力持續升高，共居合租因此成為許多人的居住解方。</span
        >
        <span class="hidden xl:block xl:text-justify"
          >而接下來討論的可負擔，則以二個族群的合理房租上限取平均一一<span
            class="font-bold"
            >11,000元</span
          >作為基準進行探討。</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "deskTop2",
  components: {},
  data() {
    return {
      dataList: [
        {
          title: "社會新鮮人",
          subtitle: "(<25歲)",
          img: "/images/graduate.png",
          age: "<25歲",
          salary: "29,083",
          rentPrice: "9,597",
          rate: "20.5%",
          text: "月租金 $9,597 房源總數 ÷ 總房源數",
        },
        {
          title: "低年資上班族",
          subtitle: "(25-29歲)",
          img: "/images/worker.png",
          age: "25-29歲",
          salary: "39,417",
          rentPrice: "13,008",
          rate: "35.3%",
          text: "月租金 $13,008 房源總數 ÷ 總房源數",
        },
      ],
    };
  },
};
</script>

<style>
.text0-7 {
  transform: scale(0.7);
}
</style>
