<template>
  <div id="affordability" class="com8 w-screen relative" ref="scrollBox">
    <div class="w-full flex flex-col justify-evenly lg:justify-center items-center sticky top-0">
        <div class="w-full h-screen absolute px-2 py-14 lg:px-20 lg:py-20 2xl:pt-32 flex flex-col justify-between z-10">
            <div class="flex justify-between items-start z-30">
                <div class=" w-3/5 lg:w-auto flex flex-col items-start lg:items-center h-24 lg:h-auto p-3 lg:px-12 lg:border border-gray-400 lg:bg-white lg:bg-opacity-70">
                    <span class="block text-lg text-left lg:text-4xl font-bold">{{title}}</span>
                    <span class="block text-xs lg:text-lg">{{subtitle}}</span>
                </div>
                <img class="trans-item w-2/5 md:w-1/5 h-auto" src="/images/desktop8_notes.png" alt="">
            </div>
        </div>
        <div class="relative w-full flex justify-center items-center">
            <div class=" w-full h-screen bg-cover bg-center bg-map8M lg:bg-map8 lg:bg-left-bottom " :class="show1 ? 'show' : ''"></div>
            <div class="mapimg absolute w-full h-screen bg-cover bg-center bg-map9M lg:bg-map9 lg:bg-left-bottom " :class="show2 ? 'opacity-100' : ''"></div>
            <div class="mapimg absolute w-full h-screen bg-cover bg-center bg-map10M lg:bg-map10 lg:bg-left-bottom " :class="show3 ? 'opacity-100' : ''"></div>
            <div class="mapimg absolute w-full h-screen bg-cover bg-center bg-map11M lg:bg-map11 lg:bg-left-bottom " :class="show4 ? 'opacity-100' : ''"></div>
            <div class="mapimg absolute w-full h-screen bg-cover bg-center bg-map12M lg:bg-map12 lg:bg-left-bottom " :class="show5 ? 'opacity-100' : ''"></div>
            <div class="mapimg absolute w-full h-screen bg-cover bg-center bg-map13M lg:bg-map13 lg:bg-left-bottom " :class="show6 ? 'opacity-100' : ''"></div>     
        </div>
    
    </div>
    <div class="com8-box scroll-first" ref="box1">
        <div class="com8-box absolute px-2 lg:px-20 lg:py-10 flex flex-col justify-center z-10">
            <div class="flex justify-center z-10 lg:justify-end items-center relative bg-white bg-opacity-60 lg:bg-none lg:bg-opacity-0"> 
                <div class=" w-full lg:w-1/4 text-justify p-3 lg:border lg:border-gray-400 lg:bg-white lg:bg-opacity-80">初次到台北的人都難免被台北市高昂的租金嚇到，若談到可負擔的租房選項，應該不少人想到的是緊鄰河道與台北市相望的新北市吧！永和、三重、蘆洲區租金便宜，騎車進城又十分便利；又或者被稱為新都心的板橋區，捷運便捷、機能完備，都是許多年輕人租屋的熱門選擇。</div>
            </div>
        </div>
    </div>
    <div class="com8-box scroll-animation" ref="box2">
        <div class="com8-box absolute px-2 lg:px-20 lg:py-10 flex flex-col justify-center z-10">
            <div class="flex justify-center z-10 lg:justify-end items-center relative bg-white bg-opacity-60 lg:bg-none lg:bg-opacity-0"> 
                <div class=" w-full lg:w-1/4 text-justify p-3 lg:border lg:border-gray-400 lg:bg-white lg:bg-opacity-80">若將數據從區再細分到里，會發現各區看似均值的色塊下，其實有著很多樣的密度差異，這也意味著常理所理解的昂貴蛋黃區，依然有許多可負擔的共居機會。</div>
            </div>
        </div>
    </div>
    <div class="com8-box scroll-animation" ref="box3">
        <div class="com8-box absolute px-2 lg:px-20 lg:py-10 flex flex-col justify-center z-10">
            <div class="flex justify-center z-10 lg:justify-end items-center relative bg-white bg-opacity-60 lg:bg-none lg:bg-opacity-0"> 
                <div class=" w-full lg:w-1/4 text-justify p-3 lg:border lg:border-gray-400 lg:bg-white lg:bg-opacity-80">中山區雖然看似很多可負擔房源集中於雙連一帶，但是坪數小，主要是勞力工作族群、周邊飯店與酒店相關從業人員在此租屋，較少學生族群和其他行業的員工居住在此。</div>
            </div>
        </div>
    </div>
    <div class="com8-box scroll-animation" ref="box4">
        <div class="com8-box absolute px-2 lg:px-20 lg:py-10 flex flex-col justify-center z-10">
            <div class="flex justify-center z-10 lg:justify-end items-center relative bg-white bg-opacity-60 lg:bg-none lg:bg-opacity-0"> 
                <div class=" w-full lg:w-1/4 text-justify p-3 lg:border lg:border-gray-400 lg:bg-white lg:bg-opacity-80">松山區近南港車站、永春、後山埤一帶，可負擔物件密度較高，隨著捷運通車以及新建案落成帶來商機與人口，雖離核心市區稍遠但周邊機能完整，而且距東區、信義區等上班區域近，是另一個共居的潛力地點。</div>
            </div>
        </div>
    </div>
    <div class="com8-box scroll-animation" ref="box5">
        <div class="com8-box absolute px-2 lg:px-20 lg:py-10 flex flex-col justify-center z-10">
            <div class="flex justify-center z-10 lg:justify-end items-center relative bg-white bg-opacity-60 lg:bg-none lg:bg-opacity-0"> 
                <div class=" w-full lg:w-1/4 text-justify p-3 lg:border lg:border-gray-400 lg:bg-white lg:bg-opacity-80">信義區、大安區、文山區三區交界一帶，雖然房屋屋齡較高，但坪數大，若找室友合住分攤下來租金便宜，周邊生活機能好，離市中心核心區近。</div>
            </div>
        </div>
    </div>
    <div class="w-full h-screen"></div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      show1: true,
      show2: false,
      show3: false,
      show4:false,
      show5:false,
      show6:false,
      title:"新北市才住得起?",
      subtitle:"一從可負擔物件密度看雙北各區"
    };
  },
  methods: {
    scroll() {
      //距離網頁最頂端之滾動距離
      let top = window.scrollY;
      //deskTop8組件離父層最頂部距離
      let scrollBox = this.$refs.scrollBox.offsetTop;
      //滾動多少高度後切換內容
      let h = (1 / 4) * this.$refs.box2.clientHeight;
      let boxHeight = this.$refs.box2.clientHeight;
      //desktop8組件 在滾動1/8時高度 
      let box0h = scrollBox + (1/2)*h;
      let box1h = scrollBox;
      //要切換到box2元素內容之滾動距離(與網頁最頂端)
      let box2h = scrollBox + this.$refs.box2.offsetTop;
     let box2Maph = box2h -h;
      //要切換到box3元素內容之滾動距離(與網頁最頂端)
      let box3h = scrollBox + this.$refs.box3.offsetTop;
      let box3Maph = box3h -h;
      //要切換到box4元素內容之滾動距離(與網頁最頂端)
      let box4h = scrollBox + this.$refs.box4.offsetTop;
      let box4Maph = box4h -h;
      //要切換到box5元素內容之滾動距離(與網頁最頂端)
      let box5h = scrollBox + this.$refs.box5.offsetTop;
      let box5Maph = box5h -h;
      //要切換到box6元素內容之滾動距離(與網頁最頂端)

      if (top < box0h) {
        this.show1 = true;
        this.show2 = false;
        this.show3 = false;
        this.show4 = false;
        this.show5 = false;
        this.show6 = false;
        this.subtitle = "一從可負擔物件密度看雙北各區";
      } else if (top > box0h && top < box2Maph) {
        this.show1 = false;
        this.show2 = true;
        this.show3 = false;
        this.show4 = false;
        this.show5 = false;
        this.show6 = false;
        this.title = "新北市才住得起？";
        this.subtitle = "一從可負擔數量密度看雙北各區";
        this.setScroll(top,box1h,boxHeight);
      } else if (top > box2Maph && top < box3Maph) {
        this.show1 = false;
        this.show2 = false;
        this.show3 = true;
        this.show4 = false;
        this.show5 = false;
        this.show6 = false;
        this.title = "蛋黃區也能可負擔？";
        this.setScroll(top,box2h,boxHeight);
      } else if (top > box3Maph && top < box4Maph) {
        this.show1 = false;
        this.show2 = false;
        this.show3 = false;
        this.show4 = true;
        this.show5 = false;
        this.show6 = false;
        this.setScroll(top,box3h,boxHeight);
      } else if (top > box4Maph && top < box5Maph) {
        this.show1 = false;
        this.show2 = false;
        this.show3 = false;
        this.show4 = false;
        this.show5 = true;
        this.show6 = false;
        this.setScroll(top,box4h,boxHeight);
      }
      else if (top > box5Maph) {
        this.show1 = false;
        this.show2 = false;
        this.show3 = false;
        this.show4 = false;
        this.show5 = false;
        this.show6 = true;
        this.setScroll(top,box5h,boxHeight);
      } 
       
     
    },
    setScroll(scroll,offset,height){
        document.body.style.setProperty("--scroll",(scroll-offset)/height);
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scroll, true);
  },
};
</script>
<style >
.com8-box{
  width: 100%;
  height: 200vh;
}
.scroll-first {
  animation: scrollFirst 1s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -1s);
  animation-iteration-count: 1;
  animation-fill-mode: both;
}
.scroll-animation {
  animation: scroll 1s linear infinite;
  animation-play-state: paused;
  animation-delay: calc(var(--scroll) * -1s);
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes scrollFirst {
  0% {
    opacity: 1;
  }
  80%{
    opacity: 1;
  }
  100%{
      opacity: 0;
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
  }
  30%{
    opacity: 1;
  } 
  50%{
    opacity: 0.3;
  }
  100%{
      opacity: 0;
  }
}

</style>
