<template>
  <div
    class="absolute"
    :style="{ width: circleData.circleSize.outWidth, left: circleData.position.left, top: circleData.position.top }"
  >
    <div class="relative w-full h-full flex justify-between items-end" :class="circleData.reverse ? 'reverse':''">
      <div
        class="opacity-0 border md:border-2 border-black border-dotted rounded-full"
        :class="show ? 'show' : ''"
        :style="{width:circleData.circleSize.width, height:circleData.circleSize.height}"
      ></div>
      <div
        class="px-1 lg:px-2 py-1 opacity-0 text-xs lg:text-sm bg-black text-center text-white"
        :class="show ? 'tag-show' : ''"
        :style="[circleData.reverse ? {'transform': 'scaleX(-1)'} : {}]"
      >
        {{circleData.text}}
      </div>
      <div
        class="w-0 h-px opacity-0 absolute bottom-0 left-1/4 bg-black"
        :class="show ? 'line-show' : ''"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapCircles",
  props: ["circleData","show"],
  data() {
    return {};
  },
};
</script>

<style>
.reverse{
  transform: scaleX(-1);
}
.show {
  transition: all 0.5s ease-in;
  opacity: 1 !important;
}
.tag-show {
  transition: all 0.5s ease-in;
  transition-delay: 0.5s;
  opacity: 1 !important;
}
.line-show {
  transition: all 0.5s ease-in;
  transition-delay: 0.5s;
  width: 75% !important;
  opacity: 1 !important;
}
</style>
