<template>
  <div class="home relative w-screen h-screen">
    <Nav-bar />
    <Desk-top1 />
    <Desk-top2 />
    <Bar-chart />
    <Desk-top6 />
    <Desk-top7 />
    <Desk-top8 />
    <!-- <Map-box /> -->
    <Desk-top14 />
    <Why />
    <Why-two />
    <!-- <Result-animal/> -->
    <Drag-scene />
    <Next-step />
    <Story-board />
    <About-us />
  </div>
</template>

<script>
// @ is an alias to /src
import DeskTop1 from "@/components/deskTop1.vue";
import DeskTop2 from "@/components/deskTop2.vue";
import BarChart from "@/components/Barchart/Barchart.vue";
import DeskTop6 from "@/components/deskTop6.vue";
import DeskTop7 from "@/components/deskTop7.vue";
import DeskTop8 from "@/components/deskTop8.vue";
import Why from "@/components/why.vue";
import WhyTwo from "@/components/why-two.vue";
import DeskTop14 from "@/components/Desktop14";
import AboutUs from "@/components/aboutUs.vue";
import DragScene from "@/components/dragScene.vue";
import NextStep from "@/components/nextStep.vue";
import StoryBoard from "@/components/storyBoard.vue";
// import ResultAnimal from '@/components/resultAnimal.vue';
import NavBar from "@/components/navBar.vue";

export default {
  name: "Home",
  components: {
    DeskTop1,
    DeskTop2,
    BarChart,
    DeskTop6,
    DeskTop7,
    DeskTop8,
    Why,
    WhyTwo,
    DeskTop14,
    DragScene,
    NextStep,
    StoryBoard,
    AboutUs,
    // ResultAnimal,
    NavBar,
  },
  data() {
    return {};
  },
  mounted() {
    //掛取換圖片resize
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  methods: {
    resize() {
      let innerWidth = window.innerWidth;
      if (innerWidth >= 1280) {
        this.$store.dispatch("getWidth", true);
      } else {
        this.$store.dispatch("getWidth", false);
      }

      this.$store.dispatch("setWidthValue", innerWidth);
    },
  },
};
</script>
