<template>
  <div id="aboutUs" class="com68 relative p-10 min-h-screen xl:p-20 2xl:p-40">
    <div class="flex flex-wrap items-stretch justify-center">
      <div class="xl:w-1/3 lg:w-full">
        <!-- 標題部分 -->
        <div class="xl:flex xl:justify-center text-left py-10">
          <p :class="['text-5xl', 'font-bold', 'xl:w-4/5', titleClass]">
            Organization
          </p>
        </div>

        <div class="flex justify-center h-4/5">
          <div
            :class="[
              'relative',
              'h-full',
              'border-2',
              'rounded-xl',
              'p-4',
              'xl:w-4/5',
              borderClass,
            ]"
          >
            <div class="flex justify-center pb-12">
              <img src="/images/believeGeneration.png" alt="" />
            </div>

            <div class="flex justify-center w-full">
              <div class="border border-textGray w-1/20"></div>
            </div>

            <p
              :class="[
                'text-2xl',
                'font-bold',
                'pb-10',
                'pt-4',
                titleClass,
              ]"
            >
              台灣相信世代發展協會
            </p>

            <div class="flex justify-center">
              <!-- <img src="/images/aboutUs-1.png" alt="" /> -->
              <p class="text-customGray text-left text-lg pb-16">
                秉持著秉持著相信而生的跨世代社群, 致力於推動台灣社會創新能量。
                成立以來,相信世代協會從永續發展、社會設計的角度關心青年探索、
                青年創作、青年居住三大領域, 協助青年創造對社會有正向意義的計畫,
                讓改變得以發生。
                若有興趣響應、參與、獲得任何與青年居住計畫相關的訊息,
                歡迎點擊下方連結！
              </p>
            </div>

            <a
              class="flex justify-end items-center text-18 font-semibold xs:absolute xs:bottom-2 xs:right-8"
              href="https://binnextgen.org/affordablehousing"
              target="blank"
            >
              <img src="/images/arrow.png" class="w-8 mr-1" alt="" />
              <span>相信世代</span>
            </a>
          </div>
        </div>
      </div>

      <div class="xl:w-1/3 xl:pb-0 lg:w-full lg:pb-10">
        <!-- 中間 -->
        <div class="text-left py-10">
          <p class="text-5xl font-bold opacity-0">none</p>
        </div>

        <div class="flex justify-center h-4/5">
          <div
            :class="[
              'relative',
              'h-full',
              'border-2',
              'rounded-xl',
              'p-4',
              'xl:w-4/5',
              borderClass,
            ]"
          >
            <div class="flex justify-center pb-5">
              <img src="/images/invisibleCity.png" alt="" />
            </div>

            <div class="flex justify-center w-full">
              <div class="border border-textGray w-1/20"></div>
            </div>

            <p
              :class="[
                'text-2xl',
                'font-bold',
                'pb-10',
                'pt-4',
                titleClass,
              ]"
            >
              IVC 在看得見的城市
            </p>

            <div class="flex justify-center">
              <!-- <img src="/images/aboutUs-1.png" alt="" /> -->
              <p class="text-customGray text-left text-lg pb-20">
                從前看不見的城市, 現在我們有機會看見了, 看見人們的情感、 活動、
                記憶。 從宏觀、 理性到微觀、 感性, 跨專業的探索城市,
                對熟悉又陌生的城市倡議。 社群夥伴橫跨都市設計、 景觀、
                建築及新聞、 城鄉等領域, 持續研究、
                發表以數據驅動創新決策的可能。
              </p>
            </div>
            <a
              class="flex justify-end items-center text-18 font-semibold xs:absolute xs:bottom-2 xs:right-8"
              href="https://www.facebook.com/ivcinvisiblecities"
              target="blank"
            >
              <img src="/images/arrow.png" class="w-8 mr-1" alt="" />
              <span>IVC 在看得見的城市</span>
            </a>
          </div>
        </div>
      </div>

      <div
        class="flex items-center justify-center w-full my-10 xl:block xl:w-1/3 lg:my-0 md:justify-between"
      >
        <!-- 右邊部分 -->
        <div class="relative">
          <div class="xl:flex xl:justify-center text-left py-10">
            <p :class="['text-5xl', 'font-bold', 'xl:w-4/5', titleClass]">
              Team
            </p>
          </div>

          <div class="flex justify-center">
            <div
              :class="[
                'border-2',
                'rounded-xl',
                'p-4',
                'xl:w-4/5',
                borderClass,
              ]"
            >
              <div
                class="flex pb-2"
                v-for="(team, teamIndex) in teamArray"
                :key="'team' + teamIndex"
              >
                <!-- <img src="/images/aboutUs-1.png" alt="" /> -->
                <span
                  class="text-black text-left font-bold text-normal md:text-lg pl-4 pr-6 whitespace-nowrap"
                  >{{ team.title }}
                </span>
                <div class="text-black text-left text-lg">
                  <span
                    v-for="(member, memberIndex) in team.member"
                    :key="'member' + memberIndex"
                  >
                    <a :href="member.link" target="_blank">{{ member.name }}</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="absolute -bottom-6 w-full text-center text-textGray text-sm xl:hidden">2021 Produced</div>
        </div>

        <div
          class="hidden flex-col items-center h-full pt-20 xl:pt-40 xl:block xl:h-auto sm:justify-end sm:flex"
        >
          <div class="text-left">
            <p
              :class="[
                'text-6xl',
                'text-center',
                'font-bold',
                'px-4',
                'xl:text-7xl',
                titleClass,
              ]"
            >
              About Us
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden absolute bottom-5 left-0 w-full xl:block 3xl:text-2xl text-textGray">2021 Produced</div>
  </div>
</template>
<script>
export default {
  name: "aboutUs",
  data() {
    return {
      titleClass: "text-customGreen",
      borderClass: "border-customGreen",
      teamArray: [
        {
          title: "數據研究",
          member: [
            { name: "洪皓哲、 ", link: null },
            { name: "陳威竹", link: "https://wz991831-2.medium.com/" },
          ],
        },
        {
          title: "設計討論",
          member: [
            {
              name: "IVC團隊夥伴",
              link: "https://www.facebook.com/ivcinvisiblecities",
            },
          ],
        },
        {
          title: "網站設計",
          member: [
            { name: "洪皓哲、 ", link: null },
            { name: "陳威竹", link: "https://wz991831-2.medium.com/" },
          ],
        },
        {
          title: "網站開發",
          member: [
            { name: "Ken、 ", link: null },
            { name: "Jason、 ", link: null },
            { name: "Jim、 ", link: null },
            { name: "Ray", link: null },
          ],
        },
        {
          title: "問卷設計",
          member: [
            { name: "吳昱明、 ", link: null },
            { name: "洪皓哲、 ", link: null },
            { name: "陳威竹", link: "https://wz991831-2.medium.com/" },
          ],
        },
        {
          title: "美編插畫",
          member: [
            {
              name: "LinCai",
              link: "https://www.instagram.com/lincaithebest/?utm_medium=copy_link",
            },
          ],
        },
        {
          title: "素材提供",
          member: [
            {
              name: "台灣相信世代發展協會",
              link: "https://binnextgen.org/affordablehousing",
            },
          ],
        },
      ],
    };
  },
};
</script>
