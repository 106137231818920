<template>
  <div class="navbar">
    <div class="top-3 right-3 lg:top-10 lg:right-12 z-sidebar fixed transition-all">
      <button v-if="burger" @click="clickBurger">
        <img class="w-8 md:w-10" src="/images/burger.png" alt="burger" />
      </button>
    </div>
    <div
      class="w-5/12 lg:w-1/4 h-full bg-white right-0 top-0 fixed z-sidebar transform transition-all shadow-lg"
      :class="[hidenav_class]"
    >
      <div class="flex justify-end p-4 lg:pr-12 lg:pt-10">
        <button @click="closeNav">
          <img class="w-5 md:w-6" src="/images/cross.png" alt="cross" />
        </button>
      </div>
      <div class="absolute top-15% left-15% flex flex-col items-start md:left-1/4 xl:left-1/3">
        <button
          v-for="nav in navArr"
          :key="nav.title"
          class="text-gray-500 text-sm md:text-lg 2xl:text-xl font-bold text-left my-5 md:my-7 2xl:my-10 hover:text-customGreen"
          @click="scrollTo(nav.link)"
        >
          {{ nav.title }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "navBar",
  data() {
    return {
      navArr: [
        { title: "Overview", link: "overview" },
        { title: "Affordability", link: "affordability" },
        { title: "Co-living x SDGs", link: "SDGs" },
        { title: "Next Step", link: "nextStep" },
        { title: "About Us", link: "aboutUs" },
      ],
      burger: true,
      hidenav_class: "translate-x-full",
    };
  },
  methods: {
    closeNav() {
      this.hidenav_class = "translate-x-full";
      this.burger = true;
    },
    clickBurger() {
      this.burger = !this.burger;
      this.hidenav_class = "";
    },
    scrollTo(com) {
      let el = document.getElementById(`${com}`);
      this.$nextTick(() => {
        window.scrollTo({ behavior: "smooth", top: el.offsetTop });
      });
    },
  },
};
</script>
