<template>
  <div
    class="
      com1
      relative
      w-screen
      h-screen
      bg-cover bg-mobileMap bg-left-top
      p-4
      flex flex-col
      justify-center
      lg:bg-left-bottom lg:bg-map
      overflow-x-hidden
    "
  >
    <div class="mb-10 w-full lg:absolute lg:bottom-10% lg:left-13%">
      <div class="mb-7 text-4xl font-bold lg:text-64 lg:mb-4">
        <p class="stroke text-customGreen text-left mb-1 lg:mb-10">Taipei Co-Living</p>
        <p class="stroke text-customGreen text-left lg:my-2">
          Guidance <span class="text-3xl font-normal text-customOrange lg:text-4xl">x </span
          ><span class="text-customOrange">SDGs</span>
        </p>
      </div>
      <img
        class="w-1/3 h-auto lg:w-60"
        src="/images/ivcCrossOver.png"
        alt=""
      />
    </div>
    <div
      class="
        w-full
        p-3
        bg-white bg-opacity-75
        border border-gray-400
        lg:w-1/4 lg:absolute lg:right-20
      "
    >
      <p class="text-lg text-justify break-words font-bold">
        高房價當道的台北，合租成為許多雙北年輕人的日常。因為經濟考量下不得不的共居，似乎也催生出新的生活文化。“Taipei Co-living Guidebook” 希望用不同的方式打開青年在台北共居的新可能，從租屋選擇、生活想像到社會創新參與(SDGs)，一同暢想共居的不同樣貌。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "deskTop1",
  components: {},
  data() {
    return {};
  },
};
</script>
<style scoped>
.stroke{
   text-shadow:
     -1px -1px 0 rgba(255,255,255, 0.5),  
      1px -1px 0 rgba(255,255,255, 0.5),
      -1px 1px 0 rgba(255,255,255, 0.5),
       1px 1px 0 rgba(255,255,255, 0.5);
}
@media (max-width: 768px) {
  .stroke{
   text-shadow: none;
}
}
</style>