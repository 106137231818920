<template>
  <div class="relative p-10 w-screen h-screen">
    <div class="w-full h-full flex flex-wrap items-center">
      <img-comparison-slider
        class="outline-none relative w-full xl:w-3/5 order-2 xl:order-1 overflow-hidden whitespace-nowrap"
        ref="dragOne"
      >
        <img
          slot="before"
          :src="`/images/dragView/drag-1${isDeskTop ? '' : 'M'}.png`"
          alt=""
        />
        <img
          slot="after"
          :src="`/images/dragView/drag-2${isDeskTop ? '' : 'M'}.png`"
          alt=""
        />
        <svg
          class="w-10 h-10 xl:w-16 xl:h-16"
          slot="handle"
          viewBox="0 0 86 86"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="42.5665" cy="42.5665" r="42.5665" fill="white" />
          <path
            d="M10.9844 43.9397L35.7004 74.8577L35.7004 13.0218L10.9844 43.9397Z"
            fill="#DADADA"
          />
          <path
            d="M71.4023 43.9397L46.6863 74.8577L46.6863 13.0218L71.4023 43.9397Z"
            fill="#DADADA"
          />
        </svg>
      </img-comparison-slider>

      <div class="w-full order-1 xl:order-2 xl:w-2/5 xl:p-10">
        <div class="px-4 py-6 border border-customGray bg-white">
          <span
            class="font-bold text-xl xl:text-2xl border-t-4 border-b-4 border-gray-200"
          >
            20坪的共居生活
          </span>
          <div class="text-sm xl:text-lg text-left mt-4">
            剛剛好的二人生活空間, 適合伴侶以及要好的摯友居住,
            用餐與下廚是主要的交流時機。
            小空間中仍可以做到落實資源回收分類與節約用電,
            傢俱選擇可以採用二手或是回收材料如木棧板等, 減輕環境負擔。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dragView_1",
  data() {
    return {};
  },
  computed: {
    isDeskTop() {
      return this.$store.state.isDeskTop;
    },
  },
};
</script>
<style lang="scss">
@media (max-width: 1280px) {
}
img-comparison-slider {
  --divider-width: 4px;
}
</style>
