<template>
  <div
    id="SDGs"
    class="w-full min-h-full flex flex-col px-4 py-12 relative bg-bgGray xl:h-screen xl:justify-center xl:px-10"
  >
    <div
      class="flex flex-col items-center xl:w-1/4 xl:items-start xl:absolute xl:top-48 xl:right-24"
    >
      <div class="text-4xl font-bold mb-5 xl:mb-14 2xl:mb-32">
        <span class="text-customGreen">Co-Living</span>
        <span class="font-normal text-xl mx-2">x</span>
        <span class="text-customOrange">SDGs</span>
      </div>
      <div
        class="text-justify w-full text-18 m-auto lg:w-3/4 xl:m-0 xl:w-full xl:pb-5 2xl:w-11/12 xl:border xl:border-gray-400 xl:bg-white xl:p-2"
      >
        {{ contents }}
      </div>
    </div>
    <div class="w-full m-auto lg:w-1/2  xl:w-3/4 2xl:w-2/3 xl:m-0 xl:pr-24 2xl:px-0">
      <img
        class="w-11/12 h-auto mx-auto xl:w-full"
        :src="isDeskTop ? '/images/whyDesktop.png' : '/images/whyMobile.png'"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "why",
  data() {
    return {
      contents:
        "共居帶來的不只是物理上坪數與空間機能的提升，也為精神上生活模式帶來更多可能性。此章節我們將分享不同物件類型室內空間的差異，並專注於公共空間的變化，與大家一同想像如何與室友打造自己喜愛的生活體驗，同時依然能創造更多社會影響力(SDGs)。",
      src: "/images/whyMobile.png",
    };
  },
  computed: {
    isDeskTop() {
      return this.$store.state.isDeskTop;
    },
  },
};
</script>
