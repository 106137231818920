<template>
  <div class="w-full">
    <div
      class="sticky top-0 w-full min-h-screen flex flex-col items-center p-4 lg:p-10 2xl:p-24 box-border bg-bgGray lg:flex-row justify-around"
    >
      <div class="w-full flex flex-col justify-center items-start lg:hidden">
        <span class="font-bold text-lg"
          >低租金大坪數:北投. 新店. 新莊. 淡水</span
        >
        <span class="text-xs">一 從單位租金與坪數看雙北各區</span>
      </div>
      <div class="w-full lg:w-2/5 flex flex-col">
        <img
          class="w-full h-auto hidden lg:block"
          src="images/desktop7.png"
          alt=""
        />
        <img
          class="w-full h-auto lg:hidden"
          src="images/desktop7M.png"
          alt=""
        />
        <div class="flex justify-end w-full mb-5 text-customGreen text-sm lg:hidden"><span class="font-bold">單位租金定義 : </span><span class="ml-1"> 月租金除以坪數</span></div>
      </div>
      <div
        class="relative w-full lg:w-60 xl:w-1/4 flex flex-col justify-center items-center lg:p-3 lg:border lg:border-gray-400 lg:bg-white"
      >
        <span class="hidden lg:block font-bold text-lg"
          >低租金大坪數:北投. 新店. 新莊. 淡水</span
        >
        <span class="hidden lg:block mb-5 text-xs"
          >一 從單位租金與坪數看雙北各區</span
        >
        <span class="block text-justify"
          >各區整租房源的平均租金與坪數做比較，發現位於北投、新店、新莊、淡水等區域的房源租金低空間又大，特別適合作為工作室或是團體活動使用。
          這些地方交通不是最方便，也不位於精華地帶，但有著較大的室內空間，也意味著有更多生活可能性。</span
        >
        <div class="hidden lg:absolute lg:flex lg:-bottom-12 lg:left-0 justify-start w-full mb-5 text-customGreen text-sm"><span class="font-bold">單位租金定義 : </span><span class="ml-1">月租金除以坪數</span></div>
      </div>
    </div>
    <div class="com7-box"></div>
  </div>
</template>

<script>
export default {
  name: "desktop7",
  components: {},
};
</script>

<style scoped>
.com7-box{
  width: 100%;
  height: 100vh;
}
</style>

