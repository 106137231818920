<template>
  <div
    class="
      relative
      flex
      items-start
      justify-center
      h-screen
      w-full
      bg-bgGray
      px-4
      py-12
      xl:justify-start
      xl:items-center xl:px-10
    "
  >
    <div v-if="isDeskTop" class="flex flex-col xl:w-3/4 xl:pr-24 2xl:w-2/3 2xl:pr-0">
      <img
        class="w-full h-auto"
        :src="isDeskTop ? '/images/whyDesktop-2.png' : ''"
        alt=""
      />

      <div class="w-full text-12 text-right py-20 pr-10">
        (平面為參考網路案例修改之房型示意圖, 非真實案例)
      </div>
    </div>

    <div
      class="flex items-start justify-center w-full xl:absolute xl:right-24 xl:w-1/4 h-full relative"
    >
      <div
        class="
          flex flex-col
          items-center
          justify-center
          absolute
          w-3/5
          left-0
          top-0
          xl:items-start
          xl:w-full xl:top-24 xl:left-auto
          md:w-2/5
        "
      >
        <p class="w-full text-18 text-left font-bold my-3 xl:w-11/12 xl:mb-14">
          公共空間的可能性
        </p>
        <div
          class="
            text-justify text-14
            w-full
            2xl:w-11/12 xl:text-18 xl:border xl:border-gray-400 xl:bg-white xl:p-2
          "
        >
          <p class="py-2">{{ contents }}</p>
          <p class="py-2">{{ contents_2 }}</p>
          <p class="py-2 pb-8">{{ contents_3 }}</p>
        </div>
      </div>

      <div v-if="!isDeskTop" class="absolute top-0 left-0 w-full">
        <div class="absolute right-30% transform translate-x-50%">
          <img class="max-h-85vh" src="/images/whyTwoM.png" alt="" />

          <div
            class="
              absolute
              -bottom-2
              right-20
              whitespace-nowrap
              text-10 text-left
            "
          >
            (平面為參考網路案例修改之房型示意圖, 非真實案例)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "whyTwo",
  data() {
    return {
      contents:
        "研究台北公寓的平面時發現, 隨著坪數變化, 臥室的坪數並不會有很明顯的變化, 空間增加的區域主要都發生在客廳等公共空間。",
      contents_2: "20坪到40坪的公寓, 共居生活的想像會有什麼不同呢？",
      contents_3:
        "公共空間可以是生活的孵化器, 能從中孕育出適合自己的共居生活; 也能藉此找到改變週遭事物的契機, 開展出屬於自己的社會行動。",
      mobileSrc: "/images/whyMobile.png",
    };
  },
  computed: {
    isDeskTop() {
      return this.$store.state.isDeskTop;
    },
  },
  methods: {},
  mounted() {},
};
</script>