<template>
  <div class="w-screen relative p-4 bg-bgGray lg:p-0" ref="scrollBox">
    <div
      class="sticky top-0 w-full h-screen flex flex-col justify-evenly items-center z-10 lg:justify-center"
    >
      <!-- ---------------標題--------------- -->
      <div class="flex flex-col items-start w-full lg:hidden">
        <div
          class="trans-item absolute w-full text-center text-3xl text-customGreen mt-4 font-bold"
          :class="show3 ? '' : 'opacity-0'"
        >
          Affordability
        </div>
        <span
          class="trans-item font-bold text-lg"
          :class="show3 ? 'opacity-0' : ''"
          >{{ title }}</span
        >
        <span class="trans-item text-sm" :class="show3 ? 'opacity-0' : ''">{{
          subTitle
        }}</span>
      </div>
      <!-- ---------------圖表相關資訊---------------- -->
      <div class="flex flex-col w-full h-1/2 lg:h-screen lg:px-20" ref="chart">
        <!-- ------Barchart------ -->
        <div
          class="relative w-full h-full lg:h-4/5 lg:m-auto lg:flex lg:items-end"
        >
          <div class="relative w-full h-full lg:w-11/12">
            <apexchart
              height="100%"
              ref="chartBar"
              :options="chartOptions"
              :series="series"
              :key="chartKey"
            ></apexchart>
            <div
              class="absolute -top-2 left-0 text-xs font-bold transform scale-75 lg:scale-100 lg:text-base lg:-top-10"
            >
              {{ chartTitle }}
            </div>
            <!-- y軸解說文字 -->
            <div class="absolute top-1/2 -left-8 text-xs transform -rotate-90 scale-75 lg:scale-100 lg:-left-10">
              物件類型計數
            </div>
            <!-- 台北市方框圖示 -->
            <transition>
              <div
                v-if="show2"
                class="absolute hidden lg:block top-10% left-6% 2xl:top-8% 2xl:left-4% w-23% py-2 text-center text-chartRed font-bold border-4 border-chartRed bg-white bg-opacity-70"
              >
                <div class="text-xl 2xl:text-2xl">台北市</div>
                <div class="text-base 2xl:text-lg">租屋房源總數前三名</div>
              </div>
            </transition>
            <!-- 新北市方框圖示 -->
            <transition>
              <div
                v-if="show2"
                class="absolute hidden lg:block left-10% top-1/4 2xl:left-9% w-28% py-2 text-center text-chartYellow font-bold border-4 border-chartYellow bg-white bg-opacity-70"
              >
                <div class="text-xl 2xl:text-2xl">新北市</div>
                <div class="text-base 2xl:text-lg">租屋房源總數前三名</div>
              </div>
            </transition>
            <!-- 中山區電腦版標記 -->
            <transition>
              <div
                v-if="show3"
                class="absolute hidden top-6% left-32% z-1 lg:flex 2xl:top-9% text-chartRed font-bold"
              >
                <div class="flex flex-col items-center">
                  <p class="text-2xl">中山區</p>
                  <span class="w-0.5 h-10 bg-chartRed"></span>
                </div>
                <div class="w-40 text-sm text-left ml-4">
                  台北房源總數最高，但大多數皆超出可負擔預算
                </div>
              </div>
            </transition>
            <!-- 板橋區電腦版標記 -->
            <transition>
              <div
                v-if="show3"
                class="absolute hidden top-15% left-3.5% z-1 lg:flex 2xl:top-19% text-chartYellow font-bold"
              >
                <div class="flex flex-col items-center">
                  <p class="text-2xl">板橋區</p>
                  <span class="w-0.5 h-10 bg-chartYellow"></span>
                </div>
                <div class="w-40 text-sm text-left ml-4">
                  新北房源總數最高，也是可負擔預算內最多房源區
                </div>
              </div>
            </transition>
            <!-- -----Barchart底下解說----- -->
            <div
              class="relative -mt-7 w-full flex flex-col items-end text-customGreen md:-mt-0 lg:absolute lg:-bottom-10"
            >
              <div
                v-if="show2 || show3"
                class="w-full text-xs text-left pl-4 lg:hidden"
                :class="show3 ? 'text-chartYellow' : 'text-chartRed'"
              >
                <span class="font-bold" :class="show3 ? 'mr-3 text-sm' : ''">{{
                  chartRankTitle
                }}</span>
                <span>{{ chartRank }}</span>
              </div>
              <div
                v-if="show2 || show3"
                class="w-full mb-4 text-xs text-left pl-4 lg:hidden"
                :class="show3 ? 'text-chartRed' : 'text-chartYellow'"
              >
                <span class="font-bold" :class="show3 ? 'mr-3 text-sm' : ''">{{
                  chartRankTitle2
                }}</span>
                <span>{{ chartRank2 }}</span>
              </div>
              <span v-if="show3" class="text-sm font-bold lg:text-lg"
                >可負擔定義：月租金$11,000以下之物件</span
              >
              <span v-if="show3" class="text-xs"
                >(包含管理費，整層以平均每房月租金計算)</span
              >
            </div>
          </div>
          <!-- ------圖例------ -->
          <div
            class="absolute top-0 right-0 w-auto lg:relative lg:mb-2 lg:ml-10 lg:w-28"
          >
            <div class="text-xs text-left">縣市</div>
            <div
              v-for="(legend, index) in legendArr"
              :key="index"
              class="flex items-center"
            >
              <span class="w-3 h-3 mr-1" :class="legend.color"></span>
              <span class="text-xs">{{ legend.text }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- -------------文字說明------------ -->
      <div
        class="relative flex w-full mt-8 lg:absolute lg:flex-col lg:w-1/4 lg:top-20 lg:right-8% 2xl:top-8%"
      >
        <span
          v-if="!show3"
          class="hidden font-bold text-xl text-left lg:block"
          >{{ title }}</span
        >
        <span v-if="!show3" class="hidden text-sm text-left mb-5 lg:block">{{
          subTitle
        }}</span>
        <div
          v-if="show3"
          class="hidden mb-14 text-4xl text-left font-bold text-customGreen lg:block"
        >
          Affordability
        </div>
        <div
          class="w-1/2 text-justify lg:w-full lg:border lg:border-gray-400 lg:p-2 lg:bg-white"
          :class="
            show3
              ? ['absolute', 'w-full', 'mt-10', 'lg:relative', 'lg:mt-0']
              : ''
          "
        >
          <span>{{ context }}</span>
          <div
            v-if="show3"
            class="hidden my-3 text-xs text-customGreen lg:block"
          >
            註：灰底長條為房源總量，顏色長條為月租金11k內之房源總量
          </div>
        </div>
        <div
          class="flex flex-col w-1/2 lg:w-full lg:flex-row lg:justify-evenly lg:mt-8"
          :class="show2 ? '' : 'opacity-0'"
        >
          <div class="flex flex-col">
            <span
              class="text-customGreen text-5xl font-bold text-right mb-1 lg:text-6xl"
              >48%</span
            >
            <span class="text-right lg:text-center font-bold text-xs mb-10"
              >台北市</span
            >
          </div>
          <div class="flex flex-col">
            <span
              class="text-customGreen text-5xl font-bold text-right mb-1 lg:text-6xl"
              >43%</span
            >
            <span class="text-right lg:text-center font-bold text-xs"
              >新北市</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="halfBoxes"></div> -->
    <div class="boxes" ref="box2"></div>
    <div class="halfBoxes"></div>
    <div class="boxes" ref="box3"></div>
    <div class="boxes"></div>
    <div class="boxes"></div>
  </div>
</template>

<script>
import chartData from "./chartData";
import {mobileConfig,desktopConfig} from "./config";
export default {
  data: function () {
    return {
      show1: false,
      show2: false,
      show3: false,
      chartKey: 0,
      legendArr: [
        { text: "新北市", color: "bg-chartYellow" },
        { text: "台北市", color: "bg-chartRed" },
      ],
      title: "雙北房源分佈",
      subTitle: "－從物件數量看雙北各區",
      context:
        "「想找租屋該從哪裡找起呢?哪邊又有最多的選項?」透過 591 數據，我們可以了解雙北各行政區租屋供給量的差異。",
      chartTitle: "雙北各區_物件總數",
      chartRankTitle: "",
      chartRankTitle2: "",
      chartRank: "",
      chartRank2: "",
      firstRender: false,
      secondRender: false,
      series: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          width: "100%",
          stacked: true,
          toolbar: {
            show: false,
          },
        },
      },
    };
  },
  isDeskTop() {
    return this.$store.state.isDeskTop;
  },
  watch: {
    show1: function (newValue) {
      if (newValue) {
        this.series = chartData.data1;
        this.chartKey++;
      }
    },
    show2: function (newValue) {
      if (newValue) {
        this.legendArr.splice(2, 1);
        this.series = chartData.data2;
        this.chartKey++;
        this.chartTitle = "雙北各區_物件總數";
      }
    },
    show3: function (newValue) {
      if (newValue) {
        this.series = chartData.data3;
        this.legendArr.push({ text: "各區房源總量", color: "bg-chartGray" });
        this.chartTitle = "雙北各區_負擔程度";
      }
    },
  },
  methods: {
    scroll() {
      //距離網頁最頂端之滾動距離
      let top = window.scrollY;
      //barChart組件離父層最頂部距離
      let scrollBox = this.$refs.scrollBox.offsetTop;
      //要切換到box2元素內容之滾動距離(與網頁最頂端)
      let box2h = scrollBox + this.$refs.box2.offsetTop;
      //要切換到box3元素內容之滾動距離(與網頁最頂端)
      let box3h = scrollBox + this.$refs.box3.offsetTop;
      // chart 第一次進入頁面要載入資料的scroll高度
      let chartH =
        scrollBox -
        this.$refs.chart.clientHeight +
        (1 / 4) * this.$refs.chart.clientHeight;

      if (top > chartH && top < box2h) {
        this.show1 = true;
        this.show2 = false;
        this.title = "雙北房源分佈";
        this.context =
          "「想找租屋該從哪裡找起呢?哪邊又有最多的選項?」透過 591 數據，我們可以了解雙北各行政區租屋供給量的差異。";
      } else if (top > box2h && top < box3h) {
        this.show1 = false;
        this.show2 = true;
        this.show3 = false;
        this.title = "房源高度集中";
        this.context =
          "不論是台北或新北，前三名的區域都囊括了 4 成以上的房源，差距懸殊。但這些房源中還包含了各種價位的物件，並非都是租得起的房源選項。";
        this.chartRankTitle = "台北市租屋房源總數前三名：";
        this.chartRankTitle2 = "新北市租屋房源總數前三名：";
        this.chartRank = "中山區、大安區、信義區";
        this.chartRank2 = "板橋區、三重區、中和區";
      } else if (top > box3h) {
        this.show2 = false;
        this.show3 = true;
        this.context =
          "若以青年可負擔的房租標準來看，雙北房源供給的排序將重新洗牌。前幾名的區域反而集中於新北市，板橋、三重和中和區有最多可負擔房源。單看台北市，前三名則為中山、內湖和士林區。";
        this.chartRankTitle = "板橋區";
        this.chartRankTitle2 = "中山區";
        this.chartRank = "房源總數最高，也是可負擔預算內最多房源區";
        this.chartRank2 = "台北房源總數最高，但大多數皆超出可負擔預算";
      }
    },
    resize() {
      let w = window.window.screen.width;
      if (w < 1280) {
        this.chartOptions = mobileConfig;
      } else {
        this.chartOptions = desktopConfig
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scroll, true);
    this.resize();
    window.addEventListener("resize", this.resize);
  },
};
</script>

<style>
.boxes {
  width: 100%;
  height: 100vh;
  position: relative;
}
.halfBoxes{
  width: 100%;
  height: 50vh;
}
.show {
  opacity: 1;
}
.z-1{
  z-index: -1;
}
.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 0.5s ease-in-out;
}
.v-enter-to,
.v-leave {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.5s ease-in-out;
}

.apexcharts-legend {
  justify-content: flex-end !important;
}
@media (max-width: 1280px) {
  .apexcharts-legend {
    justify-content: flex-start !important;
    align-items: flex-start;
  }
  .apexcharts-text {
    writing-mode: vertical-lr !important;
    letter-spacing: 1px;
  }
}
</style>
