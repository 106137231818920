<template>
  <div class="w-full">
    <div
      class="sticky top-0 w-full min-h-screen flex flex-col items-center p-4 lg:p-10 2xl:p-24 box-border bg-bgGray lg:flex-row justify-around"
    >
      <div class="w-full flex flex-col justify-center items-start lg:hidden">
        <span class="font-bold text-lg">整租房源多又便宜</span>
        <span class="text-xs">一 從可負擔占比看雙北各區</span>
      </div>
      <div class="w-full lg:w-2/5 flex flex-col">
        <img
          class="w-full h-auto hidden z-10 lg:block"
          src="images/desktop6.png"
          alt=""
        />
        <img
          class="w-full h-auto z-10 lg:hidden"
          src="images/desktop6M.png"
          alt=""
        />
        <div class="flex flex-col items-end text-customGreen z-10">
          <span class="text-sm font-bold lg:text-lg"
            >可負擔定義：月租金$11,000以下之物件</span
          >
          <span class="text-xs">(包含管理費，整層以平均每房月租金計算)</span>
        </div>
      </div>
      <div
        class="w-full lg:w-60 xl:w-1/4 flex flex-col justify-center items-center lg:p-3 lg:border lg:border-gray-400 lg:bg-white"
      >
        <span class="hidden lg:block font-bold text-lg">整租房源多又便宜</span>
        <span class="hidden lg:block mb-5 text-xs"
          >一 從可負擔占比看雙北各區</span
        >
        <span class="block text-justify"
          >除了房源量，物件類型也十分重要。大眾對租屋市場的想像大抵建構在整租、套房、雅房等類型之上。
          整租型房源是可負擔物件中比例最高的類型，也是最理想的共居物件選擇。一起承租整個公寓，青年可以共同分擔房租，又能享有客廳與較好的居住機能。</span
        >
      </div>
    </div>
    <div class="com6-box"></div>
  </div>
</template>

<script>
export default {
  name: "desktop6",
  components: {},
};
</script>

<style scoped>
.com6-box{
  width: 100%;
  height: 100vh;
}
</style>
