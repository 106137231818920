import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isDeskTop: false,
    screenWidth:''
  },
  getters: {},
  mutations: {
    SET_IS_DESKTOP(state, items) {
      state.isDeskTop = items;
    },
    SET_WIDTH(state, items) {
      state.screenWidth = items;
    },
  },
  actions: {
    getWidth(context, data) {
      context.commit("SET_IS_DESKTOP", data);
    },
    setWidthValue(context, data) {
      context.commit("SET_WIDTH", data);
    },
  },
  modules: {},
})
