export const circles = [
  {//6k-1
    position: { left: "28vw", top: "45%" },
    circleSize: { outWidth: "12vw", width: "7vw", height: "7vw" },
    text: "新莊區",
    show: 1,
    reverse: false,
  },
  {//6k-2
    position: { left: "38vw", top: "27%" },
    circleSize: { outWidth: "16vw", width: "9vw", height: "9vw" },
    text: "三重區",
    show: 1,
    reverse: false,
  },
  {//6k-3
    position: { left: "35vw", top: "70%" },
    circleSize: { outWidth: "18vw", width: "10vw", height: "10vw" },
    text: "中和區",
    show: 1,
    reverse: false,
  },
  {//9k-1
    position: { left: "52vw", top: "35%" },
    circleSize: { outWidth: "16vw", width: "7vw", height: "7vw" },
    text: "中山區",
    show: 5,
    reverse: false,
  },
  {//9k-2
    position: { left: "44vw", top: "48%" },
    circleSize: { outWidth: "16vw", width: "7vw", height: "7vw" },
    text: "萬華區",
    show: 5,
    reverse: false,
  },
  {//14k-1
    position: { left: "38vw", top: "38%" },
    circleSize: { outWidth: "10vw", width: "4vw", height: "4vw" },
    text: "三重區",
    show: 10,
    reverse: true,
  },
  {//14k-2
    position: { left: "37vw", top: "50%" },
    circleSize: { outWidth: "13vw", width: "5vw", height: "5vw" },
    text: "萬華區",
    show: 10,
    reverse: true,
  },
  {//14k-3
    position: { left: "52vw", top: "35%" },
    circleSize: { outWidth: "13vw", width: "8vw", height: "8vw" },
    text: "中山區",
    show: 10,
    reverse: false,
  },
  {//14k-4
    position: { left: "60vw", top: "48%" },
    circleSize: { outWidth: "13vw", width: "7vw", height: "7vw" },
    text: "信義區",
    show: 10,
    reverse: true,
  },
  {//14k-5
    position: { left: "50vw", top: "58%" },
    circleSize: { outWidth: "13vw", width: "8vw", height: "8vw" },
    text: "中正區",
    show: 10,
    reverse: false,
  },
];

export const circlesM = [
  {//6k-1
    position: { left: "10vw", top: "52%" },
    circleSize: { outWidth: "29vw", width: "15vw", height: "15vw" },
    text: "新莊區",
    show: 1,
    reverse: false,
  },
  {//6k-2
    position: { left: "27vw", top: "42%" },
    circleSize: { outWidth: "40vw", width: "21vw", height: "21vw" },
    text: "三重區",
    show: 1,
    reverse: false,
  },
  {//6k-3
    position: { left: "25vw", top: "67%" },
    circleSize: { outWidth: "45vw", width: "18vw", height: "18vw" },
    text: "中和區",
    show: 1,
    reverse: false,
  },
  {//9k-1
    position: { left: "40vw", top: "45%" },
    circleSize: { outWidth: "31vw", width: "18vw", height: "18vw" },
    text: "中山區",
    show: 5,
    reverse: true,
  },
  {//9k-2
    position: { left: "23vw", top: "57%" },
    circleSize: { outWidth: "31vw", width: "18vw", height: "18vw" },
    text: "萬華區",
    show: 5,
    reverse: true,
  },
  {//14k-1
    position: { left: "22vw", top: "48%" },
    circleSize: { outWidth: "25vw", width: "8vw", height: "8vw" },
    text: "三重區",
    show: 10,
    reverse: true,
  },
  {//14k-2
    position: { left: "25vw", top: "55%" },
    circleSize: { outWidth: "29vw", width: "13vw", height: "13vw" },
    text: "萬華區",
    show: 10,
    reverse: true,
  },
  {//14k-3
    position: { left: "50vw", top: "45%" },
    circleSize: { outWidth: "31vw", width: "18vw", height: "18vw" },
    text: "中山區",
    show: 10,
    reverse: false,
  },
  {//14k-4
    position: { left: "67vw", top: "52%" },
    circleSize: { outWidth: "30vw", width: "15vw", height: "15vw" },
    text: "信義區",
    show: 10,
    reverse: true,
  },
  {//14k-5
    position: { left: "52vw", top: "60%" },
    circleSize: { outWidth: "31vw", width: "18vw", height: "18vw" },
    text: "中正區",
    show: 10,
    reverse: false,
  },
];
