<template>
  <div id="nextStep" class="p-4 xl:p-40">
    <div class="flex flex-wrap justify-center">
      <div class="relative w-full md:w-1/2 md:pr-4 xl:px-20">
        <!-- 左半邊標題與動物圖片部分 -->
        <div class="text-left">
          <p :class="['text-4xl', 'font-bold', titleClass]">Next Step</p>
          <div class="pt-16 pb-4 text-black text-normal 2xl:w-8/12">
            {{ content_first }}
          </div>
          <div class="pb-4 text-black text-normal 2xl:w-8/12">
            {{ content_second }}
          </div>
          <div class="pb-14 2xl:pb-16 text-black text-normal 2xl:w-8/12">
            {{ content_third }}
          </div>
        </div>
      </div>

      <div class="relative w-full md:w-1/2 xl:pt-10 xl:px-20">
        <div class="text-right">
          <p :class="['text-6xl', 'font-bold', 'pb-4', 'xl:pt-4', titleClass]">
            ONGOING
          </p>
          <p :class="['text-6xl', 'font-bold', 'py-4', titleClass]">STORIES</p>
          <div class="pt-4 pb-4 text-right text-customGray text-lg">
            {{ describe_first }}
          </div>
          <div class="pb-4 text-right text-customGray text-lg">
            {{ describe_second }}
          </div>
          <div class="pb-4 text-right text-customGray text-lg">
            {{ describe_third }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap w-full justify-center py-20">
      <div
        class="w-full xl:w-2/3 flex flex-wrap justify-between lg:justify-around 2xl:justify-start xl:px-20 items-center"
      >
        <!-- 贊助 -->
        <div
          class="flex flex-col justify-center items-center w-1/4 xl:w-auto 2xl:mr-8 md:order-2"
        >
          <a
            href="https://binnextgen.neticrm.tw/civicrm/contribute/transact?reset=1&id=2"
            target="_blank"
            class="text-base xl:text-xl 2xl:text-2xl font-bold tracking-lg pt-1 xl:pt-2 border-t-4 border-customGreen hover:text-customGreen"
            >我想贊助</a
          >
          <p class="text-gray-400 text-xs 2xl:text-base">單筆捐款</p>
          <p class="text-gray-400 text-xs 2xl:text-base">定期定額捐款</p>
        </div>
        <!-- 按鈕 -->
        <div
          class="w-3/4 flex items-center justify-end md:w-full md:order-1 xl:hidden xl:px-20 xs:pr-0 md:pr-10 pb-10"
        >
          <img src="/images/nextStep/goToStoryMobile.png" alt="" />
        </div>
        <!-- 房東 -->
        <div
          class="flex flex-col justify-center items-center min-w-1/4 xl:min-w-0 xl:w-auto 2xl:mr-8 md:order-3"
        >
          <a
            href="mailto:Support@binnextgen.org"
            class="text-base xl:text-xl 2xl:text-2xl font-bold tracking-lg pt-1 xl:pt-2 border-t-4 border-customGreen hover:text-customGreen"
            >我是房東</a
          >
          <p class="text-gray-400 text-xs 2xl:text-base">我有物件</p>
          <p class="text-gray-400 text-xs 2xl:text-base">我想提供空間 !</p>
        </div>
        <!-- <div class="w-1/8 md:hidden"></div> -->
        <!-- 聯絡 -->
        <div
          class="flex flex-col justify-center items-center min-w-1/4 xl:min-w-0 xl:w-auto 2xl:mr-8 md:order-4"
        >
          <a
            href="mailto:Support@binnextgen.org"
            class="text-base xl:text-xl 2xl:text-2xl font-bold tracking-lg pt-1 xl:pt-2 border-t-4 border-customGreen hover:text-customGreen"
            >我想聯絡</a
          >
          <p class="text-gray-400 text-xs 2xl:text-base">有任何想法</p>
          <p class="text-gray-400 text-xs 2xl:text-base">歡迎與我們聯繫</p>
        </div>
        <!-- <div class="w-1/8 md:hidden"></div> -->
        <!-- 室友 -->
        <div
          class="flex flex-col justify-center items-center min-w-1/4 xl:min-w-0 xl:w-auto 2xl:mr-8 md:order-5"
        >
          <a
            href="https://survey.binnextgen.org"
            target="_blank"
            class="text-base xl:text-xl 2xl:text-2xl font-bold pt-1 xl:pt-2 border-t-4 border-customGreen hover:text-customGreen"
            >我想找室友</a
          >
          <p class="text-gray-400 text-xs 2xl:text-base">填寫共居問卷</p>
          <p class="text-gray-400 text-xs 2xl:text-base">讓我們更了解你</p>
        </div>
      </div>
      <dir
        class="w-full xl:w-1/3 xl:flex items-center justify-end xl:px-20 hidden"
      >
        <img src="/images/nextStep/goToStoryBoard.png" alt="" />
      </dir>
    </div>
  </div>
</template>
<script>
export default {
  name: "nextStep",
  data() {
    return {
      titleClass: "text-customGreen",
      content_first:
        "共居既是年輕人面臨房價壓力下的一種出口, 也是促成更深層交流, 凝聚社群, 甚至推動社會創新行動的起點。",
      content_second: `每個搬進台北市打拼的年輕人都可能組成共居生活, 也都能從自己出發開啟社會行動, 希望藉由"Taipei Co-living Guidebook", 邀請你一同嘗試共居的新可能。 `,
      content_third: "你就是共居的進行式(Ongoing Story)。",
      describe_first: "共居進行式不應該停在這裡",
      describe_second: "歡迎把你的想法告訴我們",
      describe_third: "你想象中的共居應該是什麼樣子",
    };
  },
};
</script>
