<template>
  <transition name="fadeNumber">
    <div
      v-if="bgId >= 1"
      class="absolute bottom-5 flex w-full pl-5 text-stroke lg:pl-0 lg:bottom-14 lg:justify-center"
    >
      <div class="text-customGreen font-bold">
        <span class="text-lg lg:text-5xl">NTD$</span>
        <span class="text-2xl ml-1 lg:text-6xl lg:ml-5">{{ start }}</span>
        <span class="text-2xl transition-all lg:text-6xl"> - {{ end }}</span>
       
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Numbers",
  props: ["bgId"],
  data() {
    return {
      numberStart: 0,
      numberEnd: 6000,
    };
  },
  computed: {
    start() {
      return this.numberStart.toLocaleString();
    },
    end() {
      return this.numberEnd.toLocaleString();
    },
  },
  methods: {
    setNumber(start,end) {
      if(this.numberStart < start && this.numberEnd < end){
        this.numberStart = start-1000;
        this.numberEnd = end-1000;
        let intval = setInterval(()=>{
        if(this.numberStart < start && this.numberEnd < end){
          this.numberStart += 100;
          this.numberEnd += 100;
        }else clearInterval(intval);
      },60)
      }else if (this.numberStart > start && this.numberEnd > end) {
        this.numberStart = start+1000;
        this.numberEnd = end+1000;
        let intval = setInterval(()=>{
         if (this.numberStart > start && this.numberEnd > end){
          this.numberStart -= 100;
          this.numberEnd -= 100;
        } else clearInterval(intval);
      },60)
      }
    },
  },
  watch: {
    bgId: function (newValue) {
      if (newValue == 2) this.setNumber(6000,7000);
      else if (newValue == 3) this.setNumber(7000,8000);
      else if (newValue == 4) this.setNumber(8000,9000);
      else if (newValue == 5) this.setNumber(9000,10000);
      else if (newValue == 6) this.setNumber(10000,11000);
      else if (newValue == 7) this.setNumber(11000,12000);
      else if (newValue == 8) this.setNumber(12000,13000);
      else if (newValue == 9) this.setNumber(13000,14000);
      else if (newValue == 10) this.setNumber(14000,15000);
      else {this.numberStart = 0; this.numberEnd = 6000;}
    },
  },
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .text-stroke{
      -webkit-text-stroke: 2px #fff;
  }
}
.fadeNumber-enter,
.fadeNumber-leave-to {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.fadeNumber-enter-to,
.fadeNumber-leave {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
</style>