<template>
  <div class="com68 p-5 2xl:p-36" v-if="Object.keys(apiResult).length > 0">
    <div class="flex flex-wrap justify-center">
      <div class="relative w-full xl:w-4/6 xl:px-10 2xl:px-20">
        <!-- 左半邊標題與動物圖片部分 -->
        <div class="text-left">
          <p :class="['text-4xl', 'font-bold', titleClass]">Story Board</p>
          <div class="pt-16 pb-14 2xl:pb-16 text-black text-lg xl:w-8/12">
            {{ content }}
          </div>

          <!-- 各圖片區塊 -->
          <div class="flex flex-wrap justify-center">
            <!-- 動物圖片與文字 -->
            <div class="w-1/2 xl:w-1/4">
              <img class="mx-auto" src="images/animal/greenCat.png" alt="" />
              <div
                class="mx-auto border-2 border-customGreen rounded-3xl w-11/12 sm:w-2/3 xl:w-11/12 2xl:w-5/6 py-2"
              >
                <div class="flex flex-wrap items-center justify-between">
                  <div
                    class="text-customGreen text-xl xl:text-lg 2xl:text-xl pl-4"
                  >
                    <div class="font-bold">貓</div>
                    <div>Cat</div>
                  </div>
                  <div
                    class="text-2xl xl:text-xl 2xl:text-2xl font-bold text-customGreen pr-4"
                  >
                    {{ Math.round(apiResult["animals_rate"].cat) }}%
                  </div>
                </div>
              </div>
              <!-- 圖片下文字敘述 -->
              <div class="mx-auto text-textGray w-2/3 xl:w-3/4 pt-6">
                你 / 妳是像貓一樣的人 不需要太多朋友 但需要深厚的情感連結
              </div>
            </div>
            <!-- 動物圖片與文字 -->
            <div class="w-1/2 xl:w-1/4">
              <img class="mx-auto" src="images/animal/greenSloth.png" alt="" />
              <div
                class="mx-auto border-2 border-customGreen rounded-3xl w-11/12 sm:w-2/3 xl:w-11/12 2xl:w-5/6 py-2"
              >
                <div class="flex flex-wrap items-center justify-between">
                  <div
                    class="text-customGreen text-xl xl:text-lg 2xl:text-xl pl-4"
                  >
                    <div class="font-bold">樹懶</div>
                    <div>Sloth</div>
                  </div>
                  <div
                    class="text-2xl xl:text-xl 2xl:text-2xl font-bold text-customGreen pr-4"
                  >
                    {{ Math.round(apiResult["animals_rate"].sloth) }}%
                  </div>
                </div>
              </div>
              <!-- 圖片下文字敘述 -->
              <div class="mx-auto text-textGray w-2/3 xl:w-3/4 pt-6">
                你 / 妳是像樹懶一樣的人 喜歡宅在家放鬆自己 覺得出門是苦差事
              </div>
            </div>
            <!-- 動物圖片與文字 -->
            <div class="w-1/2 xl:w-1/4">
              <img class="mx-auto" src="images/animal/orangeGoat.png" alt="" />
              <div
                class="mx-auto border-2 border-customOrange rounded-3xl w-11/12 sm:w-2/3 xl:w-11/12 2xl:w-5/6 py-2"
              >
                <div class="flex flex-wrap items-center justify-between">
                  <div
                    class="text-customOrange text-xl xl:text-lg 2xl:text-xl pl-4"
                  >
                    <div class="font-bold">山羊</div>
                    <div>Goat</div>
                  </div>
                  <div
                    class="text-2xl xl:text-xl 2xl:text-2xl font-bold text-customOrange pr-4"
                  >
                    {{ Math.round(apiResult["animals_rate"].goat) }}%
                  </div>
                </div>
              </div>
              <!-- 圖片下文字敘述 -->
              <div class="mx-auto text-textGray w-2/3 xl:w-3/4 pt-6">
                工作之外結合興趣的 Side Project 不僅有機會實現創業精神
                也讓自己更能找到好工作
              </div>
            </div>
            <!-- 動物圖片與文字 -->
            <div class="w-1/2 xl:w-1/4">
              <img
                class="mx-auto"
                src="images/animal/orangePeacock.png"
                alt=""
              />
              <div
                class="mx-auto border-2 border-customOrange rounded-3xl w-11/12 sm:w-2/3 xl:w-11/12 2xl:w-5/6 py-2"
              >
                <div class="flex flex-wrap items-center justify-between">
                  <div
                    class="text-customOrange text-xl xl:text-lg 2xl:text-xl pl-2"
                  >
                    <div class="font-bold">孔雀</div>
                    <div class="text-lg">Peacock</div>
                  </div>
                  <div
                    class="text-2xl xl:text-xl 2xl:text-2xl font-bold text-customOrange pr-2"
                  >
                    {{ peacockRate }}%
                  </div>
                </div>
              </div>
              <!-- 圖片下文字敘述 -->
              <div class="mx-auto text-textGray w-2/3 xl:w-3/4 pt-6">
                透過舉辦講座與工作坊 讓身旁的人在參與的過程中 逐漸了解永續的觀念
                和提升參與度
              </div>
            </div>
          </div>
        </div>

        <!-- NEXT圖片按鈕 -->
        <div
          class="hidden xl:flex items-center justify-end py-10 2xl:py-0 2xl:absolute 2xl:bottom-0 2xl:right-24"
        >
          <span class="text-2xl font-bold text-customOrange">About Us</span>
          <img class="w-8 h-auto ml-2" src="/images/orangeArrow.png" alt="" />
        </div>
      </div>

      <div class="w-full xl:w-2/6 pt-6 xl:pt-0">
        <!--  右半邊長方形堆疊區塊 -->
        <div>
          <!-- 選單 -->
          <div class="flex items-center justify-center pb-6">
            <div class="relative w-full">
              <div
                @click="setSelect"
                class="py-1 px-4 flex items-center cursor-pointer border-2 border-customGreen"
                :class="showSelect ? 'bottomGray' : ''"
              >
                <img class="pr-8" src="/images/deTriangle.png" alt="" />
                <span class="font-bold text-2xl">{{ selectedTitle }}</span>
              </div>
              <div
                v-if="showSelect"
                class="absolute w-full bg-white shadow-xl border-l-2 border-r-2 border-b-2 border-customGreen"
              >
                <div
                  v-for="(select, index) in selectList"
                  v-show="select.value != selectData"
                  :key="index"
                  @click="setSelectShow(select.value)"
                  class="px-4 py-2 flex items-center border-b-2 border-gray-200 cursor-pointer hover:bg-customGreen"
                >
                  <span class="font-bold text-2xl ml-16">{{
                    select.name
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- 第二行 -->
          <div class="flex items-center justify-center pb-3">
            <div
              class="border-2 border-customGreen p-6 pr-2 w-full flex items-center"
            >
              <img class="py-0.5 pr-4" src="/images/greenSquare.svg" alt="" />
              <div class="flex flex-wrap items-center pl-1">
                <span class="md:text-base text-xs pr-6 py-2"
                  >多數人喜歡, 居住氛圍傾向</span
                >
                <span class="md:text-xl text-base font-bold">{{
                  nowData.living_vibe
                }}</span>
              </div>
            </div>
          </div>

          <!-- 第三行 -->
          <div class="flex items-center justify-center pb-3">
            <div
              class="border-2 border-customGreen p-6 pr-2 w-full flex items-center"
            >
              <img class="pr-4" src="/images/greenTriangle.svg" alt="" />
              <div>
                <p class="md:text-base text-xs text-left pl-1 xs:pr-4">
                  {{ nowData.age }} 歲的
                  <span class="md:text-xl text-lg font-bold">{{
                    selectedTitle
                  }}</span
                  >, 願意負擔的
                </p>
                <p class="md:text-base text-xs mt-2 pl-1 pr-6 text-left">
                  月租金大約落在
                  <span class="md:text-xl text-lg font-bold">
                    ${{ nowData.budget }}元</span
                  >
                </p>
              </div>
            </div>
          </div>

          <!-- 第四行 -->
          <div class="flex items-center justify-center pb-3">
            <div
              class="border-2 border-customGreen p-6 w-full flex items-center"
            >
              <img class="pr-4" src="/images/greenCircle.svg" alt="" />
              <div>
                <p class="md:text-base text-xs pl-1 pr-6 text-left">
                  家的意象排行前三名是
                </p>
                <div class="flex mt-2 pl-1">
                  <span class="text-lg text-left md:text-xl font-bold">
                    {{ nowData.home_imagination.join("、") }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- 第五行 -->
          <div class="flex items-center justify-center pb-3">
            <div
              class="border-2 border-customGreen p-6 w-full flex items-center"
            >
              <img
                class="pr-4 rotate-90"
                src="/images/greenSquare2.svg"
                alt=""
              />

              <div>
                <p class="md:text-base text-xs pl-1 text-left">
                  最想帶到新家的物件前二名是
                </p>
                <div class="flex mt-2 pl-1">
                  <span class="text-lg text-left md:text-xl font-bold">
                    {{ nowData.living_item.join("、") }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- 第六行 -->
          <div class="flex items-center justify-center pb-3">
            <div
              class="border-2 border-customGreen p-6 w-full flex items-center"
            >
              <div
                class="flex flex-wrap w-full items-center justify-between relative"
              >
                <div>
                  <p class="md:text-base text-xs pl-1 pr-6 text-left">
                    填這份問卷的人, 目前大約有
                  </p>

                  <div
                    class="w-full rounded-xl h-2 mt-2"
                    :style="percentage(nowData.wanna_co_living_rate)"
                  ></div>
                </div>

                <div>
                  <p class="md:text-4xl text-2xl font-bold text-black">
                    {{ Math.trunc(nowData.wanna_co_living_rate) }} %
                  </p>
                </div>

                <p
                  class="md:text-base text-xs absolute bottom-1 xl:bottom-0 right-0 -mb-6"
                >
                  未來會/已經在共居中
                </p>
              </div>
            </div>
          </div>

          <!-- Survey -->
          <div class="flex items-center justify-center pb-3 pt-4">
            <a
              class="w-full"
              href="https://survey.binnextgen.org/"
              target="_blank"
            >
              <div
                class="border-2 border-customOrange p-4 w-full flex items-center"
              >
                <div class="flex flex-wrap w-full items-center justify-between">
                  <div class="md:w-5/12">
                    <p class="md:text-4xl text-2xl text-customOrange font-bold">
                      Survey
                    </p>
                  </div>

                  <div class="md:w-5/12">
                    <p
                      class="sm:text-base xl:text-xs 3xl:text-base text-xs text-left text-textGray"
                    >
                      我想知道自己的共居動物
                    </p>
                    <p
                      class="sm:text-base xl:text-xs 3xl:text-base text-xs text-left text-textGray"
                    >
                      我想了解青年共居計畫
                    </p>
                  </div>

                  <!-- 橘色箭頭圖案 -->
                  <div class="md:w-2/12 flex justify-end">
                    <img src="/images/orangeArrow.png" alt="" />
                  </div>
                </div>
              </div>
            </a>
          </div>

          <!-- NEXT圖片按鈕 -->
          <div class="xl:hidden flex items-center justify-center py-10">
            <span class="text-2xl font-bold text-customOrange">About Us</span>
            <img class="w-8 h-auto ml-2" src="/images/orangeArrow.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "storyBoard",
  data() {
    return {
      titleClass: "text-customGreen",
      content:
        "故事仍然在繼續發酵, 做完問卷的同時不僅可以知道自己屬於哪種共居動物, 你的想法也會成為具有影響力的意見之一, 被大家看見！",
      apiResult: {},
      animals_rate: {},
      showSelect: false,
      selectList: [
        {
          name: "學生",
          value: "student",
        },
        {
          name: "上班族",
          value: "office_worker",
        },
        {
          name: "打工族",
          value: "part_time_worker",
        },
        {
          name: "自由工作者",
          value: "freelancer",
        },
      ],
      selectData: "student",
    };
  },
  methods: {
    percentage(data) {
      return {
        background: `linear-gradient(90deg, #4CD964 ${data}%, #DADADA ${
          100 - data
        }%)`,
      };
    },
    setSelect() {
      this.showSelect = !this.showSelect;
    },
    setSelectShow(data) {
      this.showSelect = false;
      this.selectData = data;
    },
  },
  created() {
    fetch("https://www.sdgscolivingguide.org/api/survey/statistics/all")
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        this.apiResult = data;
        this.animals_rate = data.animals_rate;
      });
  },
  computed: {
    selectedTitle() {
      return this.selectList.find((item) => item.value === this.selectData)
        .name;
    },
    nowData() {
      return this.apiResult[this.selectData];
    },
    peacockRate() {
      let cat = Math.round(this.apiResult["animals_rate"].cat);
      let sloth = Math.round(this.apiResult["animals_rate"].sloth);
      let goat = Math.round(this.apiResult["animals_rate"].goat);
      return 100 - cat - sloth - goat;
    },
  },
};
</script>

<style scoped>
.bottomGray {
  border-bottom: 2px solid #979797;
}
</style>
