const red = "#F85A31";
const yellow = "#FFD23F";
const gray = "#A9A9A9";

const datas = {
  data1: [
    {
      name: "",
      data: [
        { x: "中山區", y: 34647, fillColor: red },
        { x: "板橋區", y: 21348, fillColor: yellow },
        { x: "大安區", y: 20796, fillColor: red },
        { x: "三重區", y: 18576, fillColor: yellow },
        { x: "信義區", y: 18412, fillColor: red },
        { x: "內湖區", y: 14880, fillColor: red },
        { x: "中和區", y: 14660, fillColor: yellow },
        { x: "新莊區", y: 13248, fillColor: yellow },
        { x: "士林區", y: 11954, fillColor: red },
        { x: "淡水區", y: 11898, fillColor: yellow },
        { x: "中正區", y: 11896, fillColor: red },
        { x: "松山區", y: 11825, fillColor: red },
        { x: "萬華區", y: 10946, fillColor: red },
        { x: "永和區", y: 9293, fillColor: yellow },
        { x: "大同區", y: 9239, fillColor: red },
        { x: "新店區", y: 8061, fillColor: yellow },
        { x: "北投區", y: 7400, fillColor: red },
        { x: "文山區", y: 7103, fillColor: red },
        { x: "蘆洲區", y: 6237, fillColor: yellow },
        { x: "南港區", y: 5710, fillColor: red },
      ],
    },
  ],
  data2: [
    {
      name: "",
      data: [
        { x: "中山區", y: 34647, fillColor: red },
        { x: "板橋區", y: 21348, fillColor: yellow },
        { x: "大安區", y: 20796, fillColor: red },
        { x: "三重區", y: 18576, fillColor: yellow },
        { x: "信義區", y: 18412, fillColor: red },
        { x: "內湖區", y: 14880, fillColor: gray },
        { x: "中和區", y: 14660, fillColor: yellow },
        { x: "新莊區", y: 13248, fillColor: gray },
        { x: "士林區", y: 11954, fillColor: gray },
        { x: "淡水區", y: 11898, fillColor: gray },
        { x: "中正區", y: 11896, fillColor: gray },
        { x: "松山區", y: 11825, fillColor: gray },
        { x: "萬華區", y: 10946, fillColor: gray },
        { x: "永和區", y: 9293, fillColor: gray },
        { x: "大同區", y: 9239, fillColor: gray },
        { x: "新店區", y: 8061, fillColor: gray },
        { x: "北投區", y: 7400, fillColor: gray },
        { x: "文山區", y: 7103, fillColor: gray },
        { x: "蘆洲區", y: 6237, fillColor: gray },
        { x: "南港區", y: 5710, fillColor: gray },
      ],
    },
  ],
  data3: [
    {
      name: "",
      data: [
        { x: "板橋區", y: 13484, fillColor: yellow },
        { x: "三重區", y: 11978, fillColor: yellow },
        { x: "淡水區", y: 10255, fillColor: yellow },
        { x: "中和區", y: 9883, fillColor: yellow },
        { x: "新莊區", y: 8600, fillColor: yellow },
        { x: "永和區", y: 6564, fillColor: yellow },
        { x: "中山區", y: 5729, fillColor: red },
        { x: "新店區", y: 5536, fillColor: yellow },
        { x: "士林區", y: 4997, fillColor: red },
        { x: "內湖區", y: 4988, fillColor: red },
        { x: "文山區", y: 4542, fillColor: red },
        { x: "信義區", y: 4480, fillColor: red },
        { x: "蘆洲區", y: 4179, fillColor: yellow },
        { x: "大安區", y: 3810, fillColor: red },
        { x: "萬華區", y: 3604, fillColor: red },
        { x: "北投區", y: 3419, fillColor: red },
        { x: "中正區", y: 3227, fillColor: red },
        { x: "大同區", y: 2514, fillColor: red },
        { x: "南港區", y: 2368, fillColor: red },
        { x: "松山區", y: 2166, fillColor: red },
      ],
    },
    {
      name: "各區房源總量",
      data: [
        { x: "板橋區", y: 21195, fillColor: gray },
        { x: "三重區", y: 18355, fillColor: gray },
        { x: "淡水區", y: 11760, fillColor: gray },
        { x: "中和區", y: 14580, fillColor: gray },
        { x: "新莊區", y: 13105, fillColor: gray },
        { x: "永和區", y: 9271, fillColor: gray },
        { x: "中山區", y: 34397, fillColor: gray },
        { x: "新店區", y: 8004, fillColor: gray },
        { x: "士林區", y: 11895, fillColor: gray },
        { x: "內湖區", y: 14810, fillColor: gray },
        { x: "文山區", y: 7076, fillColor: gray },
        { x: "信義區", y: 18293, fillColor: gray },
        { x: "蘆洲區", y: 6179, fillColor: gray },
        { x: "大安區", y: 20685, fillColor: gray },
        { x: "萬華區", y: 10788, fillColor: gray },
        { x: "北投區", y: 7324, fillColor: gray },
        { x: "中正區", y: 11789, fillColor: gray },
        { x: "大同區", y: 9134, fillColor: gray },
        { x: "南港區", y: 5669, fillColor: gray },
        { x: "松山區", y: 11738, fillColor: gray },
      ],
    },
  ],
};

export default datas;
