<template>
  <div class="relative p-10 h-screen">
    <div class="w-full h-full flex flex-wrap items-center">
      <div
        ref="outFrame_0"
        class="relative w-full xl:w-3/5 order-2 xl:order-1 overflow-hidden whitespace-nowrap"
        :style="
          isDeskTop
            ? `height: ${outFrameWidth * 0.742}px`
            : `height: ${outFrameWidth * 1.187}px`
        "
      >
        <div class="w-full h-full flex items-center justify-center">
          <img
            class="w-full"
            src="/images/dragTeach.gif"
            alt=""
          />
        </div>
      </div>

      <div class="w-full xl:w-2/5 order-1 xl:order-2 xl:p-10">
        <div class="px-4 py-6 border border-customGray bg-white">
          <span
            class="font-bold text-xl xl:text-2xl border-t-4 border-b-4 border-gray-200"
          >
            操作教學
          </span>
          <div class="text-sm xl:text-lg text-left mt-4">
            既然公共空間有這麼重要的影響性,
            那對於共居的公共空間可以有什麼樣的想像呢 ? 接下來歡迎你動動手指,
            來探索共居與SDG之間的無限可能吧！
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dragTeach",
  data() {
    return {
      outFrameWidth: "",
    };
  },
  watch:{
    screenWidth: function () {
      this.outFrameWidth = this.$refs.outFrame_0.offsetWidth;
    },
  },
  computed: {
    isDeskTop(){
      return this.$store.state.isDeskTop;
    },
    screenWidth() {
      return this.$store.state.screenWidth;
    },
  },
  mounted() {
    // 取得外層寬度
    this.outFrameWidth = this.$refs.outFrame_0.offsetWidth;
  },
};
</script>
